import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import { Row, Nav, Navbar, NavDropdown, Container } from 'react-bootstrap';

import { IoIosArrowBack } from '@react-icons/all-files/io/IoIosArrowBack';

import Player from './Player';
import '../../../resources/assets/styles/sass/YouTube.scss';
import SubList from '../_common/SubList';
import LoadingComponent from '../_common/LoadingComponent';

import { setCurrentQuestionSub } from '../../modules/video';
import { setQuestionShow, setShowYoutubeDetail } from '../../modules/common';

function YoutubeDetail({ videoId, captions, setLoading }) {

    const scrollRef = useRef();

    const [subtitles, setSubtitles] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedLangText, setSelectedLangText] = useState('언어');
    const [playTime, setPlayTime] = useState('');
    const [durationTime, setDurationTime] = useState(0);
    const [timelineTime, setTimelineTime] = useState('');
    const [scrollHeight, setScrollHeight] = useState(0);
    const [selectedIndices, setSelectedIndices] = useState([]);
    const [subListLoading, setSubListLoading] = useState(false);

    const dispatch = useDispatch();
    const subSelectMode = useSelector(state => state.video.subSelectMode);
    const openToken = useSelector(state => state.organization.openToken);

    function handleGoBack() {
        dispatch(setShowYoutubeDetail(false));
        setLoading(false);
        dispatch(setQuestionShow(false));
    }

    const handleSelect = (eventKey, event) => {
        setSelectedLanguage(eventKey);
        setSelectedLangText(event.target.textContent);
    };

    useEffect(() => {
        setSelectedLanguage(captions[0].languageCode)
        setSelectedLangText(captions[0].name.simpleText)
    }, [captions])

    useEffect(() => {
        if (selectedLanguage) {
            setSubListLoading(true);
            const data = JSON.stringify({
                "videoId": videoId,
                "langCode": selectedLanguage
            });

            const config = {
                method: 'post',
                url: '/local/csmsai/apis/youtube.php',
                headers: {
                    "organization-open-token": openToken,
                    "csmsai-request": "getYouTubeSubtitles",
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    if (response.data.code == 100) {
                        setSubListLoading(false);
                        const modifiedArray = response.data.res.map((item, index) => {
                            return {
                                ...item, // 기존 속성들을 복사
                                id: index,
                                content: item.text
                            };
                        });
                        setSubtitles(modifiedArray);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [selectedLanguage])

    const saveSubLists = (indices) => {
        const [start, end] = indices;
        const subListsToSave = subtitles.slice(start, end + 1);
        dispatch(setCurrentQuestionSub(subListsToSave));
    };

    useEffect(() => {
        if (selectedIndices.length === 2) {
            saveSubLists(selectedIndices);
        }
    }, [selectedIndices]);

    useEffect(() => {
        if (!subSelectMode) {
            setSelectedIndices([]);
            dispatch(setCurrentQuestionSub([]));
        }
    }, [subSelectMode])

    return (
        <>
            {
                captions.length > 0 && subtitles.length > 0 ?
                    <div className="aiQuizYoutube">
                        <Row style={{ width: '100%', padding: '10px 0 20px 0' }}>
                            <div className="col-6" style={{ display: 'flex', alignItems: 'center', color: 'gray', cursor: 'pointer' }} onClick={handleGoBack}>
                                <IoIosArrowBack /><div style={{ marginLeft: 10 }} >뒤로 가기</div>
                            </div>
                            <Navbar expand="lg" className="bg-body-tertiary col-6 videoNav" style={{ padding: 0, minHeight: 1, marginBottom: 0 }}>
                                <Container style={{ padding: 0 }}>
                                    <Nav className="me-auto">
                                        <NavDropdown title={selectedLangText} id="basic-nav-dropdown" onSelect={handleSelect} style={{ fontSize: 13 }}>
                                            {
                                                captions.map((caption, idx) => (
                                                    <NavDropdown.Item key={idx} eventKey={caption.languageCode}>
                                                        {caption.name.simpleText}
                                                    </NavDropdown.Item>
                                                ))
                                            }
                                        </NavDropdown>
                                    </Nav>
                                </Container>
                            </Navbar>
                        </Row>

                        <Row style={{ width: '100%' }} >
                            <div className='col-6' style={{ "padding": 0, height: 480 }}>
                                <Player videoId={videoId} setDurationTime={setDurationTime} setPlayTime={setPlayTime} timelineTime={timelineTime} />
                            </div>
                            <div className='col-6 listDiv' style={{ "padding": 0 }} ref={scrollRef}>

                                {subListLoading ?
                                    <LoadingComponent style={{ height: 410 }} />
                                    :
                                    subtitles.map((item, idx) => (
                                        <SubList key={item.id} idx={idx} item={item} playTime={playTime} setScrollHeight={setScrollHeight} setSelectedIndices={setSelectedIndices} selectedIndices={selectedIndices} />
                                    ))
                                }
                            </div>
                        </Row>
                    </div>
                    :
                    <div style={{ height: 520, width: "100%" }}>
                        <LoadingComponent />
                    </div>
            }
        </>
    );
}

export default YoutubeDetail;