const SET_QUESTION_SHOW = "video/SET_QUESTION_SHOW";
const SET_SHOW_YOUTUBE_DETAIL = "video/SET_SHOW_YOUTUBE_DETAIL";

export const setQuestionShow = questionShow => ({ type: SET_QUESTION_SHOW, questionShow })
export const setShowYoutubeDetail = showYoutubeDetail => ({ type: SET_SHOW_YOUTUBE_DETAIL, showYoutubeDetail })

const initialState = {
    questionShow: true
}

export default function common(state = initialState, action) {
    switch (action.type) {
        case SET_QUESTION_SHOW:
            return {
                ...state,
                questionShow: action.questionShow
            }
        case SET_SHOW_YOUTUBE_DETAIL:
            return {
                ...state,
                showYoutubeDetail: action.showYoutubeDetail
            }
        default:
            return state;
    }
} 