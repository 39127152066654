import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Row } from 'react-bootstrap';

import { setCurrentQuestionSub } from '../../modules/video';

dayjs.extend(isBetween);

function SubList({ idx, item, playTime, setScrollHeight, setSelectedIndices, selectedIndices }) {
    
    const dispatch = useDispatch();  

    const [isSelected, setIsSelected] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const subSelectMode = useSelector(state => state.video.subSelectMode);

    useEffect(() => {
        if (playTime) {
            if (playTime > item.start && playTime < item.end) {
                setIsSelected(true);
                setScrollHeight(idx*80-150);
            }
            else {
                setIsSelected(false);
            }
        }
    },[playTime])

    const handleSubListClick = (item) => {
        setIsSelected(true);
        dispatch(setCurrentQuestionSub(item));
    };

    function formatTime(seconds) {
        let milliseconds = seconds * 1000;
    
        let date = new Date(milliseconds);
    
        let hours = String(date.getUTCHours()).padStart(2, '0');
        let minutes = String(date.getUTCMinutes()).padStart(2, '0');
        let secs = String(date.getUTCSeconds()).padStart(2, '0');
        let millis = String(date.getUTCMilliseconds()).padStart(3, '0');
    
        return `${hours}:${minutes}:${secs}.${millis}`;
    }

    const selectItemRange = (idx) => {
        setIsClicked(true);
    
        if (selectedIndices.length === 0) {
            // 처음 선택된 경우
            setSelectedIndices([idx]);
        } else if (selectedIndices.length === 1) {
            // 두 번째 선택된 경우
            const newIndices = [...selectedIndices, idx].sort((a, b) => a - b);
            setSelectedIndices(newIndices);
        } else {
            // 이미 두 개의 요소가 선택된 경우
            const [start, end] = selectedIndices;
    
            if (idx < start) {
                // 새로 선택된 요소가 처음보다 앞에 있는 경우
                setSelectedIndices([idx, end]);
            } else if (idx > end) {
                // 새로 선택된 요소가 끝보다 뒤에 있는 경우
                setSelectedIndices([start, idx]);
            } else {
                // 새로 선택된 요소가 중간에 있는 경우
                const startDistance = Math.abs(idx - start);
                const endDistance = Math.abs(idx - end);
    
                if (startDistance < endDistance) {
                    // 처음과 더 가까운 경우
                    setSelectedIndices([idx, end]);
                } else {
                    // 끝과 더 가까운 경우
                    setSelectedIndices([start, idx]);
                }
            }
        }
    };

    const isWithinSelectedRange = selectedIndices.length === 2 &&
    selectedIndices[0] <= idx &&
    selectedIndices[1] >= idx;

    return (
        <Row key={item.id}  
            className={`subListRow ${isSelected ? 'active' : 'inactive'}`} onClick={(e) => subSelectMode ? selectItemRange(idx) : handleSubListClick(item)}
            style={{
                background: subSelectMode && (isWithinSelectedRange || (isClicked && selectedIndices.includes(idx))) ? 'lightgray' : 'white'
            }}
        >
            <div className="col-1 listItemCol1">{item.id >= 10 ? item.id : '0' + item.id}</div>
            <div className="col-3 listItemCol3">
                <div id='start-time'>
                    { formatTime(item.start) }
                </div>
                <div id='end-time'>
                    { formatTime(item.end) }
                </div>
            </div>
            <div className='col-8 listItemCol8'>
                <div id='contentBox'>
                    {item.content} 
                </div>
            </div>
        </Row>
    );
}

export default SubList;