import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

function LoadingComponent(props) {
    return (
        <div id="loadingComponent">
            <Spinner animation="border" variant="info" />
        </div>
    );
}

export default LoadingComponent;