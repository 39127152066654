const SET_CDN = "video/SET_CDN";
const SET_VS_CDN = "video/SET_VS_CDN";
const SET_VIDEO_DURATION_TIME = "video/SET_VIDEO_DURATION_TIME";
const SET_CURRENT_SUB = "video/SET_CURRENT_SUB";
const SET_SUB_SELECT_MODE = "video/SET_SUB_SELECT_MODE";
const SET_VIDEO_DETAIL_SHOW = "video/SET_VIDEO_DETAIL_SHOW";
const SET_CURRENT_QUESTION_SUB = "video/SET_CURRENT_QUESTION_SUB";
const SET_VIDEO_UUID = "video/SET_VIDEO_UUID";
const SET_LANG_CODE = "video/SET_LANG_CODE";

export const setCdn = cdn => ({ type: SET_CDN, cdn })
export const setVsCdn = vsCdn => ({ type: SET_VS_CDN, vsCdn })
export const setVideoDurationTime = durationTime => ({ type: SET_VIDEO_DURATION_TIME, durationTime })
export const setCurrentSub = currentSub => ({ type: SET_CURRENT_SUB, currentSub });
export const setSubSelectMode = subSelectMode => ({ type: SET_SUB_SELECT_MODE, subSelectMode })
export const setShowVideoDetail = showVideoDetail => ({ type: SET_VIDEO_DETAIL_SHOW, showVideoDetail })
export const setCurrentQuestionSub = currentQuestionSub => ({ type: SET_CURRENT_QUESTION_SUB, currentQuestionSub })
export const setVideoUUID = videoUUID => ({ type: SET_VIDEO_UUID, videoUUID })
export const setLangCode = langCode => ({ type: SET_LANG_CODE, langCode })

const initialState = {
    cdn: '',
    vsCdn: '',
    currentSub: [],
    durationTime: '',
    subSelectMode: false,
    showVideoDetail: false,
    currentQuestionSub: [],
    questionSub: [],
    videoUUID: '',
    langCode: '한국어'
}

export default function video(state = initialState, action) {
    switch (action.type) {
        case SET_CDN:
            return {
                ...state,
                cdn: action.cdn
            }
        case SET_VS_CDN:
            return {
                ...state,
                vsCdn: action.vsCdn
            }
        case SET_VIDEO_DURATION_TIME:
            return {
                ...state,
                durationTime: action.durationTime
            }
        case SET_CURRENT_SUB:
            return {
                ...state,
                currentSub: action.currentSub
            }
        case SET_SUB_SELECT_MODE:
            return {
                ...state,
                subSelectMode: action.subSelectMode
            }
        case SET_VIDEO_DETAIL_SHOW:
            return {
                ...state,
                showVideoDetail: action.showVideoDetail
            }
        case SET_CURRENT_QUESTION_SUB:
            return {
                ...state,
                currentQuestionSub: action.currentQuestionSub
            }
        case SET_CURRENT_QUESTION_SUB:
            return {
                ...state,
                currentQuestionSub: action.currentQuestionSub
            }
        case SET_VIDEO_UUID:
            return {
                ...state,
                videoUUID: action.videoUUID
            }
        case SET_LANG_CODE:
            return {
                ...state,
                langCode: action.langCode
            }
        default:
            return state;
    }
} 