// utils.js
export function getKoLabel(el) {
    const langMap = {
        "ko": "한국어",
        "en": "영어",
        "ja": "일본어",
        "zhCN": "중국어 - 간체",
        "zhTW": "중국어 - 번체",
        "vi": "베트남어",
        "id": "인도네시아어",
        "th": "태국어",
        "de": "독일어",
        "ru": "러시아어",
        "es": "스페인어",
        "it": "이탈리아어",
        "fr": "프랑스어"
    };

    let lang = el.lang.replace('-', '');

    el.koLabel = langMap[lang] || "";
    return el;
}