import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import axios from 'axios';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { FaArrowRight } from '@react-icons/all-files/fa/FaArrowRight';

import LoadingComponent from './_common/LoadingComponent';
import YoutubeDetail from './Youtube/YoutubeDetail';
import '../../resources/assets/styles/sass/YouTube.scss';

import { setQuestionShow, setShowYoutubeDetail } from '../modules/common';
import { setSubSelectMode } from '../modules/video';

function Youtube({ videoId, setVideoId }) {
    const [url, setUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [captions, setCaptions] = useState(false);
    const [wrongUrl, setWrongUrl] = useState(false);

    const dispatch = useDispatch();
    const showYoutubeDetail = useSelector(state => state.common.showYoutubeDetail);
    const openToken = useSelector(state => state.organization.openToken);

    const handleUrlChange = (e) => {
        setUrl(e.target.value);
    };

    function extractVideoId() {
        let videoID = null;

        if (url.includes('youtu.be')) {
            // youtu.be 형식의 URL
            videoID = url.split('youtu.be/')[1].split('?')[0];
        } else if (url.includes('youtube.com')) {
            // youtube.com 형식의 URL
            const urlParams = new URLSearchParams(new URL(url).search);
            videoID = urlParams.get('v');
        }

        setVideoId(videoID);
    }

    useEffect(() => {
        return (() => {
            setVideoId('');
            dispatch(setShowYoutubeDetail(false));
            dispatch(setSubSelectMode(false));
        })
    }, [])

    useEffect(() => {
        if (videoId) {
            setLoading(true);

            const data = JSON.stringify({
                "videoId": videoId
            });

            const config = {
                method: 'post',
                url: '/local/csmsai/apis/youtube.php',
                headers: {
                    "organization-open-token": openToken,
                    "csmsai-request": "getCaptionTracks",
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(config)
                .then((response) => {
                    if (response.data.code == 100) {
                        console.log('aaa', response.data.res)
                        setCaptions(response.data.res);
                    }
                    setWrongUrl(false);
                })
                .catch((error) => {
                    console.log(error);
                    setWrongUrl(true);
                });
        }
    }, [videoId]);


    useEffect(() => {
        console.log('captions', captions)
        if (captions.length > 0) {
            dispatch(setShowYoutubeDetail(true));
            dispatch(setQuestionShow(true));
        }
    }, [captions])

    return (
        <>
            {
                showYoutubeDetail ?
                    <YoutubeDetail videoId={videoId} captions={captions} setLoading={setLoading} />
                    :
                    loading ?
                        <div style={{ margin: "246px 0px" }}>
                            <LoadingComponent />
                        </div>
                        :
                        <div className="youtubeContainer">
                            <Row style={{ width: "50%" }}>
                                <Col md="12">
                                    <Form>
                                        <Form.Group controlId="formUrl">
                                            <Form.Label>YouTube URL을 입력하세요.</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="https://www.youtube.com/watch?v=Z3-r715ubDg"
                                                    value={url}
                                                    onChange={handleUrlChange}
                                                />
                                                <Button variant="primary" onClick={extractVideoId}>
                                                    <FaArrowRight />
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form>
                                </Col>
                                <Col>
                                    <div>{wrongUrl && "잘못된 URL 입니다"}</div>
                                </Col>
                            </Row>
                        </div>
            }
        </>
    );
}

export default Youtube;