//Actions
const PURGE = "organization/PURGE";

const SET_OPEN_TOKEN = "organization/SET_OPEN_TOKEN";
const SET_ORGANIZATION_LIST = "organization/SET_ORGANIZATION_LIST";
const SET_ORGANIZATION_CODE = "organization/SET_ORGANIZATION_CODE";
const SET_POPUP_ORGANIZATION_DATA = "organization/SET_POPUP_ORGANIZATION_DATA";

// 액션 생성 함수
export const purgeOrganiztion = () => ({ type: PURGE });

export const setOpenToken = openToken => ({ type: SET_OPEN_TOKEN, openToken });
export const setOrganizationList = organizationList => ({ type: SET_ORGANIZATION_LIST, organizationList });
export const setOrganizationCode = organizationCode => ({ type: SET_ORGANIZATION_CODE, organizationCode });
export const setPopupOrganizationData = popupOrganizationData => ({ type: SET_POPUP_ORGANIZATION_DATA, popupOrganizationData });

// 초깃값 (상태가 객체가 아니라 그냥 숫자여도 상관 없습니다.)
const initialState = {
  organizationList: [],
  openToken: '',
  popupOrganizationData: {},
  organizationCode: ''
};

export default function organization(state = initialState, action) {
  switch (action.type) {
    case PURGE: // 받아왔던 기관 리스트는 남겨준다.
      return {
        ...state,
        organizationData: {},
        popupOrganizationData: {}
      }
    case SET_OPEN_TOKEN:
      return {
        ...state,
        openToken: action.openToken
      }
    case SET_ORGANIZATION_LIST:
      return {
        ...state,
        organizationList: action.organizationList
      }
    case SET_ORGANIZATION_CODE:
      return {
        ...state,
        organizationCode: action.organizationCode
      }
    case SET_POPUP_ORGANIZATION_DATA:
      return {
        ...state,
        popupOrganizationData: action.popupOrganizationData
      }
    default:
      return state;
  }
} 