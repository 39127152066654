import { combineReducers } from 'redux';

import organization from './organization';
import video from './video';
import common from './common';

/*
import user from './user';
import organization from './organization';
import status from './status';
import subtitle from './subtitle';
import chapter from './chapter';
import editor from './editor';
*/
const rootReducer = combineReducers({ organization, video, common });

export default rootReducer;
