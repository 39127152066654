import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Row, Button } from 'react-bootstrap';

import WaveSurfer from "wavesurfer.js";
import TimelinePlugin from '../../../../node_modules/wavesurfer.js/dist/plugins/timeline.esm.js'
import Minimap from '../../../../node_modules/wavesurfer.js/dist/plugins/minimap.esm.js'
import ZoomPlugin from 'wavesurfer.js/dist/plugins/zoom.esm.js'
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js'

import { VscZoomIn } from '@react-icons/all-files/vsc/VscZoomIn';
import { VscZoomOut } from '@react-icons/all-files/vsc/VscZoomOut';

const formWaveSurferOptions = ref => ({
  container: ref,
  waveColor: "#eee",
  progressColor: "#99ccff",
  cursorColor: "#4b89dc",
  responsive: true,
  height: 150,
  normalize: true,
  partialRender: true,
  autoCenter: true,
  minPxPerSec: 100,
  dragToSeek: true,
  interact: false,
  plugins: [
    TimelinePlugin.create(),
    Minimap.create({
      height: 20,
      waveColor: '#ddd',
      progressColor: '#999',
    }),
  ],
});

function WaveSurferTimeline({ wavesurfer, setTimelineTime, durationTime, uuid, parsedSub }) {
  const containerRef = useRef(null);
  const waveformRef = useRef(null);
  const wsRegionsRef = useRef(null);

  const [zoomLevel, setZoomLevel] = useState(50);
  const [notFound, setNotFound] = useState(true);
  const [isZoomSliderVisible, setIsZoomSliderVisible] = useState(true);

  const cdn = useSelector(state => state.video.cdn);

  useEffect(() => {
    if (durationTime && parsedSub.length > 0) {
      const fetchPeaks = async () => {
        try {
          const response = await fetch(`${cdn}/${uuid}/extData/${uuid}.peaks`);
          if (response.status === 404) {
            setNotFound(true);
            return;
          } else {
            setNotFound(false);
            const peaksData = await response.json();
            const waveSurferOptions = formWaveSurferOptions(waveformRef.current);
            waveSurferOptions.peaks = peaksData;
            waveSurferOptions.duration = durationTime;

            if (wavesurfer.current) {
              wavesurfer.current.destroy();
              wavesurfer.current = null;
            }

            wavesurfer.current = WaveSurfer.create(waveSurferOptions);
            wsRegionsRef.current = wavesurfer.current.registerPlugin(RegionsPlugin.create());

            wavesurfer.current.on("ready", () => {

              if (wavesurfer.current) {
                parsedSub.forEach(el => {
                  wsRegionsRef.current.addRegion({
                    id: 'subtitle',
                    start: el.start,
                    end: el.end,
                    content: el.content,
                    color: el.color,
                    drag: false,
                    resize: false,
                  });
                });

                wavesurfer.current.zoom(50)

                wsRegionsRef.current.on('region-clicked', (region, e) => {
                  e.stopPropagation();
                  setTimelineTime(region.start)
                });

                containerRef.current.on('mouseenter', () => {
                  console.log('in')
                  setIsZoomSliderVisible(true);
                });

                containerRef.current.on('mouseleave', () => {
                  console.log('out')
                  setIsZoomSliderVisible(false);
                });

              }
            });

            // const handleScroll = (event) => {
            //   console.log('sssss')
            //   if (wavesurfer) {
            //     console.log('j2oajp')
            //     const scrollLeft = waveformRef.current.scrollLeft;
            //     const totalWidth = waveformRef.current.scrollWidth - waveformRef.current.clientWidth;
            //     const scrollPosition = (scrollLeft / totalWidth) * wavesurfer.getDuration();
            //     wavesurfer.seekTo(scrollPosition / wavesurfer.getDuration());
            //   }
            // };

            // const wrapper = wavesurfer.current.getWrapper();
            // console.log(wrapper);
            // if (wrapper) {
            //   wrapper.addEventListener('scroll', handleScroll);
            // }

            // const wrapper = wavesurfer.current.getWrapper();
            // console.log(wrapper);
            // if (wrapper) {
            //   wrapper.addEventListener('wheel', (e) => {
            //     console.log('e.deltaY',e.deltaY)
            //     wrapper.scrollLeft += e.deltaY;
            //   }, {});
            // }

            // waveformRef.current.addEventListener('wheel', handleWheel);
            // 스크롤 이벤트 핸들러
            const handleWheel = (event) => {
              event.preventDefault();
              const scrollAmount = event.deltaY !== 0 ? event.deltaY : event.deltaX;

              // 현재 뷰포트의 시작 시간과 끝 시간 계산
              const duration = wavesurferRef.current.getDuration();
              const visibleDuration = wavesurferRef.current.drawer.width / wavesurferRef.current.params.minPxPerSec;
              const currentTime = wavesurferRef.current.getCurrentTime();

              // 스크롤에 따라 이동할 시간 계산
              const newTime = currentTime + (scrollAmount / 1000) * (duration / visibleDuration);

              // 새로운 시간을 설정
              wavesurferRef.current.seekTo(Math.max(0, Math.min(1, newTime / duration)));
            };

            wavesurfer.current.on("interaction", (newTime) => {
              console.log('interaction', newTime);
              wavesurfer.seekTo(newTime);
            });
            wavesurfer.current.on("scroll", (visibleStartTime, visibleEndTime, scrollLeft, scrollRight) => {
              console.log('scroll', visibleStartTime, visibleEndTime, scrollLeft, scrollRight);
            });
            // wavesurfer.current.on("drag", (x) => {
            //   console.log('dragstart', x);
            //   const waveformWidth = wrapper.offsetWidth;
            //   var seekToTime = x / waveformWidth;
            //   wavesurfer.current.seekTo(10);
            //   console.log(waveformWidth, seekToTime)
            // });

            const wrapper = wavesurfer.current.getWrapper();
            if (wrapper) {
              wrapper.addEventListener('wheel', handleWheel);
            }
            wavesurfer.current.on("dragstart", (x) => {
              console.log('dragend', x);
              const waveformWidth = wrapper.offsetWidth;
              var seekToTime = x / waveformWidth;
              wavesurfer.current.seekTo(10);
              console.log(waveformWidth, seekToTime)
            });
          }
        } catch (error) {
          console.error("Error fetching peaks data:", error);
        }
      };

      fetchPeaks();
    }
  }, [durationTime, parsedSub]);

  // useEffect(() => { 
  //   if (Object.keys(currentSub).length > 0) {
  //     playerRef.current.currentTime(currentSub.start/1000);
  //   }
  // },[currentSub]);


  function handleZoomLevel(e) {
    const minPxPerSec = e.target.valueAsNumber;
    setZoomLevel(minPxPerSec);
    wavesurfer.current.zoom(minPxPerSec)
  }

  const zoomLevels = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];

  const getNextZoomLevel = (currentLevel, direction) => {
    const currentIndex = zoomLevels.indexOf(currentLevel);
    if (direction === 'plus') {
      return zoomLevels[Math.min(currentIndex + 1, zoomLevels.length - 1)];
    } else {
      return zoomLevels[Math.max(currentIndex - 1, 0)];
    }
  };

  const ZoomPlus = () => {
    setZoomLevel((prevZoomLevel) => {
      const newZoomLevel = getNextZoomLevel(prevZoomLevel, 'plus');
      wavesurfer.current.zoom(newZoomLevel);
      return newZoomLevel;
    });
  };

  const ZoomMinus = () => {
    setZoomLevel((prevZoomLevel) => {
      const newZoomLevel = getNextZoomLevel(prevZoomLevel, 'minus');
      wavesurfer.current.zoom(newZoomLevel);
      return newZoomLevel;
    });
  };

  useEffect(() => {
    console.log('notFound', notFound)
  }, [notFound])

  return (
    <>
      {
        !notFound &&
        <Row ref={containerRef} style={{ width: "100%", position: "relative" }}>
          <div id="waveform" ref={waveformRef} style={{ width: "100%" }} />
          <div className='zoomSlider' >
            <div style={{ marginRight: 10 }} onClick={() => ZoomMinus()}><VscZoomOut size="16" /></div>
            <div style={{ marginRight: 10 }}>
              <input id="zoomLevelSlider" type="range" min="2" max="100" value={zoomLevel} onChange={(e) => handleZoomLevel(e)} step="1" list="tickmarks" />
            </div>
            <div onClick={() => ZoomPlus()}><VscZoomIn size="16" /></div>
          </div>
        </Row>
      }
    </>
  );
}

export default WaveSurferTimeline;