import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { Button, Spinner } from 'react-bootstrap';


const BtnLoading = ({ onGenerating, setOnGenerating, setIsSessionExist }) => {

    const [sessionData, setSessionData] = useState({ percent: 0, overHour: 0 });

    useEffect(() => {
        const selectedOption = document.querySelector('#id_selectacategory option:checked');
        const category = selectedOption.value.split(",")[0];

        checkReqState(category);
        const intervalId = setInterval(() => {
            checkReqState(category);
        }, 5000);

        return () => {
            clearInterval(intervalId);
        }
    }, []);

    async function checkReqState(category) {

        var reqData = {};
        reqData['category_id'] = category;

        axios.post("/local/csmsai/apis/quiz.php", reqData, {
            headers: {
                "Content-Type": "application/json",
                "csmsai-request": "getReqState"
            }
        })
            .then(function (response) {
                setOnGenerating(false);

                if (response.data.session !== 'undefined') {
                    var tmpSessionData = {};

                    if (typeof response.data.progressData != "undefined") {
                        if (typeof response.data.progressData.successPercent == 'undefined') {
                            tmpSessionData.percent = 0;
                        }
                        else {
                            tmpSessionData.percent = response.data.progressData.successPercent;
                        }
                        setSessionData(tmpSessionData)
                    }

                    if (response.data.session === false || tmpSessionData.percent === 100 || tmpSessionData.overHour === true) {
                        setIsSessionExist(false);
                    }
                    else {
                        setIsSessionExist(true);
                    }

                } else {
                    console.log("Session doesn't Exists");
                }
            })
            .catch(function (error) {
                console.error(error);
                setOnGenerating(false);
                setIsSessionExist(false);
            });
    }

    return (
        <>
            {
                onGenerating ?
                    <Button variant="warning">
                        생성중인 퀴즈 세션이 있습니다. <br /><Spinner animation="grow" variant="dark" size="sm" />
                    </Button>
                    :
                    <Button variant="warning">
                        생성중인 퀴즈 세션이 있습니다.<br />
                        {sessionData.percent}% <Spinner animation="grow" variant="dark" size="sm" />
                    </Button>
            }
        </>
    );
}

export default BtnLoading; 