import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';

import Text from '../components/Text/Text';
import Video from '../components/Video';
import Youtube from '../components/Youtube';
import Document from '../components/Document';
import QuestionBank from '../components/QuestionBank';

import '../../resources/assets/styles/sass/Main.scss';
import BtnQuestion from '../components/_common/BtnQuestion';

import { setQuestionShow } from '../modules/common';

function ModalFrame({ show, setShow, setOnGenerating }) {

    const [loading, setLoading] = useState(false);
    const [categoryLabel, setCategoryLabel] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [success, setSuccess] = useState(false);

    const [selectedType, setSelectedType] = useState('text');
    const [selectedLang, setSelectedLang] = useState('ko-KR');
    const [selectedLevel, setSelectedLevel] = useState(3);
    const [textQuizData, setTextQuizData] = useState('');
    const [questions, setQuestions] = useState([]);

    const [videoId, setVideoId] = useState(null);
    const videoUUID = useSelector(state => state.video.videoUUID);
    const questionShow = useSelector(state => state.common.questionShow);
    const langCode = useSelector(state => state.video.langCode);

    const dispatch = useDispatch();
    var timer;

    useEffect(() => {
        const selectedOption = document.querySelector('#id_selectacategory option:checked');
        if (selectedOption && selectedOption.textContent && selectedOption.value) {
            setCategoryLabel(selectedOption.textContent);
            setCategoryId(selectedOption.value.split(",")[0]);
        }

        return () => {
            clearTimeout(timer);
            setLoading(false);
            setCategoryLabel('');
            setCategoryId('');
            setSuccess(false);
            setSelectedType('text');
            setSelectedLang('ko-KR');
            setSelectedLevel(3);
            setTextQuizData('');
            setQuestions([]);
        };

    }, [])

    useEffect(() => {
        if (success) {
            setOnGenerating(true);
        }
    }, [success])

    const handleType = (e) => {
        setSelectedType(e.target.value);
        setQuestions([]);

        if (e.target.value == 'text') {
            dispatch(setQuestionShow(true))
        }
        else {
            dispatch(setQuestionShow(false))
        }
    };

    const handleLang = (e) => {
        setSelectedLang(e.target.value);
    };

    const handleLevel = (e) => {
        setSelectedLevel(e.target.value);
    };

    const renderComponent = () => {
        switch (selectedType) {
            case 'text':
                return <Text setQuizData={setTextQuizData} />;
            case 'video':
                return <Video setQuestions={setQuestions} />;
            case 'youtube':
                return <Youtube videoId={videoId} setVideoId={setVideoId} />;
            case 'document':
                return <Document />;
            case 'questionBank':
                return <QuestionBank />;
            default:
                return null;
        }
    };

    function requestQuestionGenerate() {

        var questionOpts = [];
        if (questions.length > 0) {

            questions.map((el) => {
                var tempQuestionOpts = {};
                tempQuestionOpts.questionType = el.questionType;
                tempQuestionOpts.questionCount = el.questionCount;

                if (selectedType == "video" || selectedType == "youtube") {
                    tempQuestionOpts.timeStart = el.questionData.timeStart;
                    tempQuestionOpts.timeEnd = el.questionData.timeEnd;
                    tempQuestionOpts.baseData = el.questionData.text;
                }
                if (el.questionType == "multichoice") {
                    tempQuestionOpts.optionCount = el.multiChoiceCount;
                }
                questionOpts.push(tempQuestionOpts);
            })

            // baseData가 빈 값인지 검사하는 로직 추가
            let hasEmptyBaseData = questionOpts.some(opt => selectedType == "video" || selectedType == "youtube" ? !opt.baseData : false);

            if (hasEmptyBaseData) {
                alert('자막 선택을 완료해주세요.');
                return; // 함수 종료
            }

            var reqData = {
                category_id: categoryId,
                baseType: selectedType,
                targetLang: selectedLang,
                targetLevel: selectedLevel,
                questionOpts: questionOpts
            };

            if (selectedType == "video") {
                reqData.vodUUID = videoUUID;
                reqData.langCode = langCode;
            }
            else if (selectedType == "youtube") {
                reqData.videoId = videoId;
            }
            else if (selectedType == "text") {
                if (textQuizData) {
                    reqData.baseData = textQuizData;
                }
                else {
                    alert('퀴즈 내용을 모두 입력 후 완료 버튼을 눌러주세요');
                    return; // 함수 종료
                }
            }
            setLoading(true);

            axios.post("/local/csmsai/apis/quiz.php", reqData, {
                headers: {
                    "csmsai-request": "setReqGenQuestion",
                    "Content-Type": "application/json",
                }
            })
                .then(response => {
                    setQuestions([]);
                    if (response.data.message == "success") {
                        setSuccess(true);
                        timer = setTimeout(() => {
                            setLoading(false);
                            setShow(false);
                        }, 1000);
                    }
                    else {
                        setSuccess(false);
                        setLoading(false);
                        setShow(false);
                    }
                })
                .catch(error => {
                    console.error("Submission Failed: ", error);
                })
        }
        else {
            alert('퀴즈 내용을 모두 입력 후 완료 버튼을 눌러주세요');
        }
    }

    return (
        <Modal
            animation={false}
            show={show}
            onHide={() => setShow(false)}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            backdropClassName="csmsAi-quizGenerator-backdrop"
            id="csmsAi-quizGenerator-modal"
            dialogClassName="modal-90w csmsAi-quizGenerator-modalDialog"
            centered
            enforceFocus={false}
        >
            <Modal.Header className="text-white header-color" closeButton>
                <Modal.Title>
                    AI QuizGenerator
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    !loading ? (
                        success ? (
                            <div className="text-center" style={{ 'margin': '30px' }}>
                                <div>요청이 완료 되었습니다.</div>
                                <br />
                                <div>생성까지 시간이 걸리니 기다려 주시기 바랍니다.</div>
                                <br />
                                <div>
                                    <i className="fa fa-solid fa-check text-success" style={{ 'fontSize': '30px' }}></i>
                                </div>
                            </div>
                        ) : (
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div id="label-category"><span style={{ fontWeight: 500 }}>문제은행 - {categoryLabel} ({categoryId})</span></div>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-sm-12">
                                        <b style={{ "color": "#cc183c" }}>
                                            요청은 한 세션에 한 번씩 가능 합니다.
                                        </b>
                                    </div>
                                </div>

                                <hr />
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="select-baseType">자료</label>
                                            </div>
                                            <select className="custom-select" id="select-baseType" value={selectedType} onChange={(e) => handleType(e)}>
                                                <option value="text" >텍스트</option>
                                                <option value="video">영상</option>
                                                <option value="youtube">YouTube</option>
                                                {process.env.NODE_ENV === 'development' && <option value="document">문서</option>}
                                                {process.env.NODE_ENV === 'development' && <option value="questionBank">문제은행</option>}
                                            </select>
                                        </div>

                                        <hr />
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="select-targetLang">언어</label>
                                            </div>
                                            <select className="custom-select" id="select-targetLang" value={selectedLang} onChange={(e) => handleLang(e)}>
                                                <option value="ko-KR">한국어</option>
                                                <option value="en-US">English (US)</option>
                                                <option value="en-GB">English (UK)</option>
                                                <option value="ja-JP">日本語</option>
                                                <option value="zh-CN">中文(简体)</option>
                                                <option value="zh-TW">中文(繁體)</option>
                                                <option value="vi-VN">Tiếng Việt</option>
                                                <option value="es-ES">Español (España)</option>
                                                <option value="es-MX">Español (México)</option>
                                                <option value="fr-FR">Français</option>
                                                <option value="de-DE">Deutsch</option>
                                                <option value="it-IT">Italiano</option>
                                                <option value="pt-PT">Português (Portugal)</option>
                                                <option value="pt-BR">Português (Brasil)</option>
                                                <option value="ru-RU">Русский</option>
                                                <option value="ar-SA">العربية</option>
                                                <option value="hi-IN">हिन्दी</option>
                                                <option value="bn-BD">বাংলা</option>
                                                <option value="pl-PL">Polski</option>
                                                <option value="tr-TR">Türkçe</option>
                                                <option value="nl-NL">Nederlands</option>
                                            </select>
                                        </div>
                                        <hr />
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <label className="input-group-text" htmlFor="select-level">난이도</label>
                                            </div>
                                            <select className="custom-select" id="select-level" value={selectedLevel} onChange={(e) => handleLevel(e)}>
                                                <option value="5">최상</option>
                                                <option value="4">상</option>
                                                <option value="3">중</option>
                                                <option value="2">하</option>
                                                <option value="1">최하</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-10">
                                        {renderComponent()}
                                    </div>
                                </div>

                                {
                                    questionShow ?
                                        <>
                                            <hr />
                                            <BtnQuestion selectedType={selectedType} questions={questions} setQuestions={setQuestions} />
                                        </>
                                        :
                                        <div>{questionShow}</div>
                                }
                            </div>

                        )
                    ) : (
                        <div className="text-center" style={{ 'margin': '30px' }}><div>생성 요청 중입니다.</div><br /><Spinner animation="border" variant="info" /></div>
                    )}

            </Modal.Body>
            <Modal.Footer>
                {
                    !loading && questionShow ?
                        <Button variant="primary" onClick={() => requestQuestionGenerate()}>요청하기</Button>
                        :
                        null
                }
            </Modal.Footer>
        </Modal>
    );
}

export default ModalFrame;