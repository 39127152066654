import React, {useState, useEffect} from 'react';


import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Common from './Common';
import { useSelector, useDispatch } from 'react-redux';
import { Cookies } from 'react-cookie';

import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
//import 'react-toastify/dist/ReactToastify.css';
//import './resources/assets/styles/sass/Toast.scss';


function App() {

    // console.log = function() {}

    const dispatch = useDispatch();

    const cookies = new Cookies();

  return (
    <BrowserRouter>
      <Common/>
      <ToastContainer />
    </BrowserRouter>
  )

}

export default App; 