import React from 'react';

function QuestionBank(props) {
    return (
        <div>
            QuestionBank
        </div>
    );
}

export default QuestionBank;