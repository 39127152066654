import React, { useState, useEffect } from 'react';

import { Button, Spinner } from 'react-bootstrap';

import ModalFrame from '../../containers/ModalFrame';

const BtnOnReady = ( {setOnGenerating} ) => {

    const [show, setShow] = useState(false);

    const handleShow = () => setShow(true);

    useEffect(() => {
        return() => {
            setShow(false);
        }
    },[])

    return (
        <>
            <Button variant="primary" onClick={() => handleShow()}>AI 퀴즈 생성기</Button>

            <ModalFrame show={show} setShow={setShow} setOnGenerating={setOnGenerating} />
        </>
        
    );
}

export default BtnOnReady; 