import React, { useState, useEffect, useRef } from 'react';
import videojs from "video.js";
import dayjs from 'dayjs';
import hlsQualitySelector from "videojs-hls-quality-selector";
import { useSelector, useDispatch } from 'react-redux';
import { setVideoDurationTime } from '../../modules/video';

videojs.registerPlugin('hlsQualitySelector', hlsQualitySelector);

function Player({ wavesurfer, setDurationTime, setPlayTime, timelineTime, uuid, selectedLang }) {
    const videoRef = useRef(null); 
    const playerRef = useRef(null);
    const dispatch = useDispatch();
    const currentQuestionSub = useSelector(state => state.video.currentQuestionSub);
    const cdn = useSelector(state => state.video.cdn);
    const vsCdn = useSelector(state => state.video.vsCdn);

    const [player, setPlayer] = useState(null);
    const [vttCdn, setVttCdn] = useState('');

    useEffect(() => {
      return () => {
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      };
    },[])
    
    useEffect(() => {
      if (vsCdn && uuid) {
        const videoJsOptions = {
          autoplay: false,
          playbackRates: [0.5, 1, 1.25, 1.5, 2],
          controls: true,
          height: '100%',
          width: '100%',
          sources: [
            {
              src: vsCdn + "/hls/" + uuid + '/mp4/' + uuid + '.mp4/index.m3u8',
              type: 'application/x-mpegURL',
            },
          ],
          plugins: {
            hlsQualitySelector: {
              displayCurrentQuality: true
            }
          }
        };

        if (!playerRef.current) {
          const videoElement = videoRef.current;
          if (!videoElement) return;

          setPlayer(
            playerRef.current = videojs(videoElement, videoJsOptions, () => {
              console.log("player is ready");
            })
          );
        }
      }
    }, [vsCdn, uuid]);


    useEffect(() => {
      if (selectedLang && cdn && uuid) {
          const lang = selectedLang.lang;
          let vttUrl;
          if (lang.includes('-')) {
              const langParts = lang.split('-');
              const autoLang = langParts[1];
              vttUrl = `${cdn}/${uuid}/subtitle/auto/${uuid}-${autoLang}.vtt`;
          } else {
              vttUrl = `${cdn}/${uuid}/subtitle/${lang}/${uuid}-${lang}.vtt`;
          }
          setVttCdn(vttUrl);
      }
  }, [cdn, uuid, selectedLang]);

  useEffect(() => {
    if (vttCdn && player) {
      player.addRemoteTextTrack({src: vttCdn, label : selectedLang.koLabel, kind: 'subtitles', mode: "showing" }, true);
    }
  },[vttCdn, player])

  useEffect(() => {
      if (wavesurfer && player){
          player.on("loadedmetadata", () => {
            setDurationTime(player.duration());
            dispatch(setVideoDurationTime(player.duration()));
          });
          player.on("timeupdate", () => {
            setPlayTime(player.currentTime());
            syncPlayerAndWavesurfer();
          });
      }
    }, [wavesurfer, player]);

    useEffect(() => {
        if (Object.keys(currentQuestionSub).length > 0) {
          playerRef.current.currentTime(currentQuestionSub.start);
        }
      },[currentQuestionSub]);

    useEffect(() => {
        if (timelineTime) {
          playerRef.current.currentTime(timelineTime);
        }
      },[timelineTime]);

    function syncPlayerAndWavesurfer() {
        var currentTime = player.currentTime();
        var waveCurrentTime = wavesurfer.current.getCurrentTime();
    
        if (currentTime < player.duration() && Math.abs(currentTime - waveCurrentTime) > 0.1) {
            wavesurfer.current.seekTo(currentTime / player.duration());
        }
  
        requestAnimationFrame(syncPlayerAndWavesurfer);
    }

    return (
        <div className="videoContatiner" >
            <div id="videoEl" data-vjs-player>
                <video ref={videoRef} id="videoEl" className="video-js vjs-big-play-centered" >
                </video>
            </div>
        </div>
    );
}

export default Player;