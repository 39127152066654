import React, { useState, useEffect, useRef }  from 'react';
import { useSelector, useDispatch } from 'react-redux';

import videojs from "video.js";
import 'videojs-youtube';

import '../../../resources/assets/styles/sass/YouTube.scss';

function Player({ videoId, setDurationTime, setPlayTime, timelineTime }) {

    const videoRef = useRef(null); 
    const playerRef = useRef(null);
    
    const currentQuestionSub = useSelector(state => state.video.currentQuestionSub);

    const [player, setPlayer] = useState(null);

    useEffect(() => {
        if (videoId) {
          var videoJsOptions = {
            autoplay: false,
            playbackRates: [0.5, 1, 1.25, 1.5, 2],
            controls: true,
            height: '100%',
            width: '100%',
            techOrder: ['youtube'],
            sources: [{
              type: 'video/youtube',
              src: `https://www.youtube.com/watch?v=${videoId}`
            }]
          };
    
          if (!playerRef.current) {
            
            const videoElement = videoRef.current;
            if (!videoElement) return;

            setPlayer( 
                playerRef.current = videojs(videoElement, videoJsOptions, () => {
                    console.log("player is ready");

                })
            )
          }
        }
    }, [videoId]);

      useEffect(() => {
   
        if (player){
          
            player.on("loadedmetadata", () => {
              setDurationTime(player.duration());
            });
            player.on("timeupdate", () => {
              setPlayTime(player.currentTime());
            });
            player.on("play", () => {
              console.log("IS Playinggg");
              // setPlayState(true);
            });
            player.on("pause", () => {
              console.log("IS STOPPED");
              // setPlayState(false);
            });
        } 
      }, [player]); 

      useEffect(() => {
        if (Object.keys(currentQuestionSub).length > 0) {
          playerRef.current.currentTime(currentQuestionSub.start);
        }
      },[currentQuestionSub]);

      useEffect(() => {
        if (timelineTime) {
          playerRef.current.currentTime(timelineTime);
        }
      },[timelineTime])

      return (
        <div className="videoContatiner" >
            <div id="videoEl" data-vjs-player>
                <video ref={videoRef} id="videoEl" className="video-js" >
                </video>
            </div>
        </div>
      );
}

export default Player;