import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

function VideoListPagination({ maxPage, setCurrentPage, currentPage }) {
    const [pageNumbers, setPageNumbers] = useState([]);
    const [startPage, setStartPage] = useState(1);
    const [endPage, setEndPage] = useState(5);

    useEffect(() => {
        const tmpArr = [];
        for (let i = 1; i <= maxPage; i++) {
            tmpArr.push(i);
        }
        setPageNumbers(tmpArr);
    }, [maxPage]);

    useEffect(() => {
        const newStartPage = Math.max(1, currentPage - 2);
        const newEndPage = Math.min(newStartPage + 4, maxPage);
        setStartPage(newStartPage);
        setEndPage(newEndPage);
    }, [currentPage, maxPage]);

    return (
        <div className='paginationContainer'>
            <Pagination className='customPagination'>
                <Pagination.First onClick={() => setCurrentPage(1)} />
                <Pagination.Prev 
                    onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))} 
                />
                {
                    pageNumbers.slice(startPage - 1, endPage).map((number, index) => {
                        return (
                            <Pagination.Item 
                                className={currentPage === number ? 'page__numbers active' : 'page__numbers'} 
                                onClick={() => setCurrentPage(number)} 
                                key={index}
                            >
                                {number}
                            </Pagination.Item>
                        )
                    })
                }
                <Pagination.Next 
                    onClick={() => setCurrentPage(Math.min(currentPage + 1, maxPage))} 
                />
                <Pagination.Last onClick={() => setCurrentPage(maxPage)} />
            </Pagination>
        </div>
    );
}

export default VideoListPagination;