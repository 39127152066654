import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import BtnOnReady from './app/components/_common/BtnOnReady';
import BtnLoading from './app/components/_common/BtnLoading';

import { setOpenToken } from './app/modules/organization';
import { Button, Spinner } from 'react-bootstrap';

const Common = () => {

  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const [isSessionExist, setIsSessionExist] = useState(false); // checkReqState 응답에서 session status 저장
  const [onGenerating, setOnGenerating] = useState(false);

  useEffect(() => {

    const selectedOption = document.querySelector('#id_selectacategory option:checked');
    const category = selectedOption.value.split(",")[0];

    var reqData = {};
    reqData['category_id'] = category;

    axios.post("/local/csmsai/apis/quiz.php", reqData, {
      headers: {
        "Content-Type": "application/json",
        "csmsai-request": "getReqState"
      }
    })
      .then(function (response) {
        if (response.data.session !== 'undefined') {
          setInitialLoading(false);
          var tmpSessionData = {};
          if (typeof response.data.progressData != "undefined") {
            if (typeof response.data.progressData.successPercent == 'undefined') {
              tmpSessionData.percent = 0;
            }
            else {
              tmpSessionData.percent = response.data.progressData.successPercent;
            }
          }

          if (response.data.session === false || tmpSessionData.percent === 100 || tmpSessionData.overHour === true) {
            setIsSessionExist(false);
          }
          else {
            setIsSessionExist(true);
          }

          //setInitialLoading(false); // 응답 잘 들어온다면 초기 로딩은 끝
        } else {
          console.log("Session doesn't Exists");
        }
      })
      .catch(function (error) {
        console.error(error);
      });

    axios.post("/local/csmsai/apis/quiz.php", null, {
      headers: {
        "Content-Type": "application/json",
        "csmsai-request": "getOpenToken"
      }
    })
      .then(function (response) {
        dispatch(setOpenToken(response.data.res.openToken));
      })
      .catch(function (error) {
        console.error(error);
      });
  }, [])

  return (
    <>
      {
        initialLoading ?
          <Button variant="info" size="sm" id="btn-modal-quizGenerator" style={{ float: 'right' }}>
            <Spinner animation="border" variant="light" size="sm" role="status" aria-hidden="true" />
          </Button>
          :
          (onGenerating || isSessionExist ?
            <BtnLoading onGenerating={onGenerating} setOnGenerating={setOnGenerating} setIsSessionExist={setIsSessionExist} />
            :
            <BtnOnReady setOnGenerating={setOnGenerating} />
          )
      }
    </>
  );
}

export default Common; 