import React from 'react';

function Text( {setQuizData}) {
    const handleQuizData = (e) => {
        setQuizData(e.currentTarget.textContent);
    };

    return (
        <div className='block-editable'>
            <div className="editable-text" contentEditable="true" 
                placeholder="퀴즈 데이터"
                onInput={(e) => handleQuizData(e)}
                suppressContentEditableWarning={true}
            />
        </div>
    );
}

export default Text;