import './resources/assets/styles/sass/Common.scss';

import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import { persistStore } from "redux-persist";
import rootReducer from './app/modules';
import logger from 'redux-logger';

import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';


if(IS_DEV){
    console.log("It's DEV");
    var store = createStore(rootReducer, composeWithDevTools(applyMiddleware(ReduxThunk, logger)) );
}else{
    var store = createStore(rootReducer );    
    console.log = function() {}

}

const persistor = persistStore(store);

const mainElement = document.createElement('div');
mainElement.id = "csmsai-quizGenerator";
mainElement.style.float = 'right';

const container = document.querySelector('.questionbankwindow');
if (container) {
    const h2Element = container.querySelector('h2');

    if (h2Element) {
        h2Element.style.marginBottom = '30px';
    }
}



if (container) {
    container.insertBefore(mainElement, container.firstChild);
    ReactDOM.render(
        <Provider store={store} >
            <App />
        </Provider>,
        mainElement
    );
}
